import { Fade, Stack, Typography, Box, Grid } from '@mui/material';
import { smeStyles, formStyles } from '../styles/sme';
import { FADE_TIMEOUT } from '../styles/stylesUtils';
import { images } from '../assets/assets';
import TextInput from '../common/TextInput';
import { useCallback, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import validator from '../common/validator';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../redux/store';
import { useSendPokoMutation } from '../redux/api';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';

export default function ContactSales() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    name: reduxName,
    email: reduxEmail,
    numberOfSeats,
  } = useAppSelector((state) => state.checkout.checkoutData, shallowEqual);

  const [name, setName] = useState(reduxName);
  const [email, setEmail] = useState(reduxEmail);
  const [numberOfEmployees, setNumberOfEmployees] = useState(
    numberOfSeats >= 500 ? numberOfSeats.toString() : '500',
  );

  const [anythingElse, setAnythingElse] = useState('');

  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [numberOfEmployeesError, setNumberOfEmployeesError] = useState('');

  const [sendMessage, { isLoading }] = useSendPokoMutation();

  const onInputChange = useCallback(
    (onChange: (text: string) => void, setError: (text: string) => void) => {
      return (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      ) => {
        setError('');
        onChange(event.target.value);
      };
    },
    [],
  );

  const onNumberOfEmployeesBlur = useCallback(() => {
    const parsed = parseInt(numberOfEmployees);
    if (isNaN(parsed)) {
      setNumberOfEmployeesError(t('invalidNumber'));
    } else if (parsed < 500) {
      setNumberOfEmployeesError(t('minimumNumberOfEmployeesForPoko'));
    }
  }, [numberOfEmployees, t]);

  const onBlurCheckEmpty = useCallback(
    (value: string, setError: (value: string) => void) => {
      return () => {
        if (value.length === 0) {
          setError(t('thisFieldIsRequired'));
        }
      };
    },
    [t],
  );

  const onNameBlur = useMemo(
    () => onBlurCheckEmpty(name, setNameError),
    [name, onBlurCheckEmpty],
  );

  const onEmailBlur = useCallback(() => {
    if (email.length === 0) {
      setEmailError(t('thisFieldIsRequired'));
    } else if (!validator.validEmail(email)) {
      setEmailError(t('invalidEmail'));
    }
  }, [email, t]);

  const hasErrors = useMemo(() => {
    const parsed = parseInt(numberOfEmployees);

    return (
      name.length === 0 ||
      email.length === 0 ||
      !validator.validEmail(email) ||
      isNaN(parsed) ||
      parsed < 500
    );
  }, [email, name.length, numberOfEmployees]);

  const onSendPressed = useCallback(async () => {
    onNameBlur();
    onEmailBlur();
    onNumberOfEmployeesBlur();

    if (!hasErrors) {
      await sendMessage({
        fullName: name,
        email,
        numberOfEmployees: parseInt(numberOfEmployees),
        message: anythingElse,
      });
      navigate('/sme/8', { replace: true });
    }
  }, [
    anythingElse,
    email,
    hasErrors,
    name,
    navigate,
    numberOfEmployees,
    onEmailBlur,
    onNameBlur,
    onNumberOfEmployeesBlur,
    sendMessage,
  ]);

  return (
    <Fade in={true} timeout={FADE_TIMEOUT} appear={true}>
      <Stack
        sx={[smeStyles.content, { maxWidth: '576px' }]}
        justifyContent={'space-between'}
        alignSelf={'center'}
        alignItems={'center'}
      >
        <Typography variant={'h1'}>{t('contactSales')}</Typography>

        <Box sx={[formStyles.container, { gap: '20px' }]}>
          <Stack sx={formStyles.formContainer}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <TextInput
                  placeholder={t('fullName')}
                  value={name}
                  endIcon={images.form.nameInput}
                  onChange={onInputChange(setName, setNameError)}
                  onBlur={onNameBlur}
                  helperText={nameError}
                  error={nameError.length > 0}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextInput
                  placeholder={t('email')}
                  value={email}
                  endIcon={images.form.email}
                  onChange={onInputChange(setEmail, setEmailError)}
                  onBlur={onEmailBlur}
                  helperText={emailError}
                  error={emailError.length > 0}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextInput
                  placeholder={t('numberOfEmployees')}
                  value={numberOfEmployees}
                  endIcon={images.form.nameInput}
                  type={'number'}
                  onChange={onInputChange(
                    setNumberOfEmployees,
                    setNumberOfEmployeesError,
                  )}
                  InputProps={{ type: 'number' }}
                  helperText={numberOfEmployeesError}
                  error={numberOfEmployeesError.length > 0}
                  onBlur={onNumberOfEmployeesBlur}
                />
              </Grid>

              <Grid item xs={12}>
                <TextInput
                  placeholder={t('anythingElse')}
                  value={anythingElse}
                  onChange={onInputChange(setAnythingElse, () => {})}
                  rows={7}
                  multiline={true}
                  sx={{ '& .MuiInputBase-root': { alignItems: 'flex-start' } }}
                />
              </Grid>
            </Grid>
          </Stack>
        </Box>

        <LoadingButton
          variant="contained"
          endIcon={<Box component={'img'} src={images.button.arrowRight} />}
          sx={{
            alignSelf: 'center',
            margin: '20px 0px 40px',
          }}
          onClick={onSendPressed}
          disabled={hasErrors}
          loading={isLoading}
        >
          {t('send')}
        </LoadingButton>
      </Stack>
    </Fade>
  );
}
