import { Input, InputProps } from '@mui/material';
import { SystemStyleObject, Theme } from '@mui/system';
import { forwardRef, useMemo } from 'react';
import { useMobile } from '../common/hooks';

const LargeInput = forwardRef<unknown, InputProps>((props, inputRef) => {
  const { sx, ...rest } = props;

  const isMobile = useMobile();
  const sxProp = useMemo<SystemStyleObject>(() => {
    return {
      '& .MuiInput-input': {
        fontSize: isMobile ? '24px' : '36px',
        lineHeight: isMobile ? '24px' : '36px',
      },
    };
  }, [isMobile]);

  return (
    <Input
      ref={inputRef}
      placeholder="Last name"
      sx={[
        {
          '&.MuiInput-root': {
            '::before': {},
            '&.MuiInput-root:hover': {
              '::before': {},
            },
          },
          '&.MuiInput-root.Mui-focused': {
            '::after': {},
          },
          '& .MuiInput-input': {
            textAlign: 'center',
            fontSize: '64px',
            lineHeight: '64px',
            fontWeight: '600',
            caretColor: '#292D3233',
            '::placeholder': {
              color: '#292D3233',
            },
          },
        },
        sxProp,
        sx as (
          theme: Theme,
        ) => SystemStyleObject<Theme> | SystemStyleObject<Theme>,
      ]}
      {...rest}
    />
  );
});

export default LargeInput;
