import { Box, Stack } from '@mui/material';
import { useMemo } from 'react';

import { useMobile, useSmallDevice } from '../common/hooks';
import ProgressBar from './ProgressBar';
import { headerStyles } from '../styles/header';
import { images } from '../assets/assets';
import { useAppSelector } from '../redux/store';
import HeaderBack from './HeaderBack';
import { useTranslation } from 'react-i18next';

export default function Header({ step, poko }: Props) {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const smallDevice = useSmallDevice();

  const planId = useAppSelector((state) => state.checkout.checkoutData.planId);

  const title = useMemo(() => {
    if (step < 3) {
      return t('signUp');
    } else if (step >= 3 && step < 5) {
      return t('details');
    } else if (step === 7) {
      return t('payment');
    } else {
      return `${planId === 1 ? t('piki') : t('paka')} ${t('plan')}`;
    }
  }, [planId, step, t]);

  const headerContent = useMemo(() => {
    return (
      <Box sx={headerStyles.progressBarContainer}>
        {step !== 1 && step !== 8 && <HeaderBack />}

        <ProgressBar
          progress={(step / 5) * 100}
          step={step}
          text={title}
          subtitleText={
            poko
              ? ''
              : step <= 5
              ? `${t('step')} ${step} / 5`
              : t('sydIsReadyToUse')
          }
        />
      </Box>
    );
  }, [poko, step, t, title]);

  if (isMobile) {
    return (
      <Stack
        sx={[
          headerStyles.topContainer,
          { margin: '20px 0px 0px 0px', width: '100%' },
          smallDevice ? { marginTop: '5px' } : {},
        ]}
        direction="column"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ alignSelf: 'stretch', position: 'relative' }}
        >
          <Box
            component="img"
            sx={[{ width: '48px', height: '48px' }, headerStyles.logo]}
            src={images.header.sydLogo}
          />

          <Box sx={headerStyles.topContainerMobile}>
            <Box
              component={'img'}
              sx={headerStyles.close}
              src={images.header.iconClose}
            />
          </Box>
        </Stack>

        <Stack sx={headerStyles.header} direction="column">
          {headerContent}
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack sx={headerStyles.topContainer}>
      <Stack sx={headerStyles.header} direction="row">
        <Box
          component={'img'}
          sx={headerStyles.logo}
          src={images.header.sydLogo}
        />

        {headerContent}

        <Box sx={headerStyles.closeIconContainer}>
          <Box
            component={'img'}
            sx={headerStyles.close}
            src={images.header.iconClose}
          />
        </Box>
      </Stack>
    </Stack>
  );
}

interface Props {
  step: number;
  poko?: boolean;
}
