import sydLogo from './syd_logo.svg';
import iconClose from './icon_close.svg';

import nameInputUnfocused from './input_name_unfocused.svg';
import accessCodeUnfocused from './access_code_unfocused.svg';
import emailUnfocused from './email_unfocused.svg';
import passwordUnfocused from './password_unfocused.svg';

import nameInputFocused from './input_name_focused.svg';
import accessCodeFocused from './access_code_focused.svg';
import emailFocused from './email_focused.svg';
import passwordFocused from './password_focused.svg';

import nameInputFilled from './input_name_filled.svg';
import accessCodeFilled from './access_code_filled.svg';
import emailFilled from './email_filled.svg';
import passwordFilled from './password_filled.svg';

import arrowRight from './button_arrow_right.svg';

import paka from './ic_paka.svg';
import piki from './ic_piki.svg';
import pakaDisabled from './ic_paka_disabled.svg';
import geneticInsights from './ic_genetic_insights.svg';
import checkSelected from './check_selected.svg';

import cardNameUnfocused from './card_name_unfocused.svg';
import cvcUnfocused from './card_cvc_unfocused.svg';
import cardExpiryDateUnfocused from './card_expiry_date_unfocused.svg';
import cardNumberUnfocused from './card_number_unfocused.svg';

import cardNameFocused from './card_name_focused.svg';
import cvcFocused from './card_cvc_focused.svg';
import cardExpiryDateFocused from './card_expiry_date_focused.svg';
import cardNumberFocused from './card_number_focused.svg';

import cardNameFilled from './card_name_filled.svg';
import cvcFilled from './card_cvc_filled.svg';
import cardExpiryDateFilled from './card_expiry_date_filled.svg';
import cardNumberFilled from './card_number_filled.svg';

import poweredByStripe from './powered_by_stripe.svg';

import clear from './clear.svg';

import summaryBackground from './summary_background.svg';
import summaryBackgroundMobile from './summary_background_mobile.svg';
import summaryAvatar from './summary_avatar.svg';

import allDone from './all_done.png';
import back from './back.svg';

export const images = {
  header: {
    sydLogo,
    iconClose,
  },

  form: {
    nameInput: {
      unfocused: nameInputUnfocused,
      focused: nameInputFocused,
      filled: nameInputFilled,
    },
    accessCode: {
      unfocused: accessCodeUnfocused,
      focused: accessCodeFocused,
      filled: accessCodeFilled,
    },
    email: {
      unfocused: emailUnfocused,
      focused: emailFocused,
      filled: emailFilled,
    },
    password: {
      unfocused: passwordUnfocused,
      focused: passwordFocused,
      filled: passwordFilled,
    },
    clear,
  },

  plans: {
    paka,
    piki,
    pakaDisabled,
    geneticInsights,
    checkSelected,
  },

  button: {
    arrowRight,
  },

  cardForm: {
    cardName: {
      focused: cardNameFocused,
      unfocused: cardNameUnfocused,
      filled: cardNameFilled,
    },
    cvc: {
      focused: cvcFocused,
      unfocused: cvcUnfocused,
      filled: cvcFilled,
    },
    cardExpiryDate: {
      focused: cardExpiryDateFocused,
      unfocused: cardExpiryDateUnfocused,
      filled: cardExpiryDateFilled,
    },
    cardNumber: {
      focused: cardNumberFocused,
      unfocused: cardNumberUnfocused,
      filled: cardNumberFilled,
    },
    poweredByStripe,
  },

  summary: {
    summaryBackgroundMobile,
    summaryBackground,
    summaryAvatar,
  },

  allDone,

  navigation: {
    back,
  },
};
