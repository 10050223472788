import { createStylesUtils } from './stylesUtils';

export const headerStyles = createStylesUtils({
  topContainer: {
    margin: '40px 40px 0px 40px',
    width: 'calc(100% - 80px)',
    position: 'relative',
  },

  header: {
    margin: '0px 0px 0px 0px',
  },

  progressBarContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    alignSelf: 'stretch',

    position: 'relative',
  },

  closeIconContainer: {
    width: '72px',
    height: '72px',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  topContainerMobile: {
    position: 'absolute',
    top: 0,
    right: '16px',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  logo: {
    width: '76px',
    height: '76px',
  },

  close: {
    width: '36px',
    height: '36px',
  },
});

export const largeInputStyles = createStylesUtils({
  container: {
    width: '100%',
    maxWidth: '700px',
    padding: '0px 0px',
    alignSelf: 'center',
  },

  countryInput: {
    width: 'calc(100% - 40px)',
    maxWidth: '700px',
    alignSelf: 'center',

    '& .MuiInput-input': {
      borderWidth: '1px',
    },
    '&.MuiInput-root': {
      margin: `0px 0px`,
      ':before, :after': {
        borderBottom: '2px solid #292D32',
      },
    },
  },

  countryStartAdornment: (theme) => ({
    '&.MuiInputAdornment-positionStart': {
      marginRight: theme.spacing(1),
    },
  }),
});

export const countryStyles = createStylesUtils({
  phone: {
    fontSize: '16px',
    color: '#A09FA3',
    marginRight: '12px',
    fontWeight: '500',
  },

  countryLabel: {
    fontSize: '16px',
    color: 'black',
    fontWeight: '500',
  },
});
