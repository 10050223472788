import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

export const createStylesUtils = <T extends object>(t: {
  [key in keyof T]: AppStyle;
}) => {
  return t;
};

export type AppStyle =
  | SystemStyleObject<Theme>
  | ((theme: Theme) => SystemStyleObject<Theme>);

export const FADE_TIMEOUT = 300;
