import { TextField, TextFieldProps, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { useMemo, useState } from 'react';

enum FieldState {
  NotFocused,
  Focused,
  Filled,
}

export default function TextInput(
  props: TextFieldProps & {
    endIcon?: { unfocused: string; focused: string; filled: string };
  },
) {
  const { endIcon, focused, sx: sxProp, ...rest } = props;

  const [fieldState, setFieldState] = useState<FieldState>(
    FieldState.NotFocused,
  );

  const imgSrc = useMemo(() => {
    if (!endIcon) {
      return undefined;
    }

    if (focused || fieldState === FieldState.Focused) {
      return endIcon.focused;
    }
    return endIcon.unfocused;
  }, [endIcon, fieldState, focused]);

  const sx = useMemo<SystemStyleObject<Theme>>(() => {
    if (focused) {
      return {
        '& .MuiOutlinedInput-input::placeholder': {
          color: '#292D32',
        },
      };
    }

    return null;
  }, [focused]);

  return (
    <TextField
      sx={[sx, sxProp as SystemStyleObject<Theme>]}
      variant="outlined"
      onFocus={() => setFieldState(FieldState.Focused)}
      onBlur={() => setFieldState(FieldState.NotFocused)}
      InputProps={endIcon ? { endAdornment: <img src={imgSrc} /> } : {}}
      {...rest}
    />
  );
}
