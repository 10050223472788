import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
} from 'react-router-dom';
import SMERouter from './router/SMERouter';
import { ThemeProvider } from '@mui/material';
import { appTheme } from './theme';
import './general-sans.css';
import './App.css';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import CatchAllRoute from './builder_io/CatchAllRoute';
import { appEnvironment } from './common/environment';
import BlogPage from './builder_io/BlogPage';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const routes: RouteObject[] = [
  {
    path: '/blog/:slug',
    element: <BlogPage />,
  },
  {
    path: '*',
    element: <CatchAllRoute />,
  },
];
if (appEnvironment.showSME) {
  routes.push({
    path: '/sme/:step',
    element: <SMERouter />,
  });
}

const router = createBrowserRouter(routes);

export default function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const locale = localStorage.getItem('locale');
    if (locale === 'ja-JP') {
      i18n.changeLanguage('ja');
    } else if (locale === 'ar-001') {
      document.getElementById('root')?.setAttribute('dir', 'rtl');
      i18n.changeLanguage('ar');
    } else {
      i18n.changeLanguage('en');
    }
  }, [i18n]);

  return (
    <Provider store={store}>
      <ThemeProvider theme={appTheme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </Provider>
  );
}
