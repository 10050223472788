import { images } from '../../assets/assets';
import { createStylesUtils } from '../../styles/stylesUtils';

export const summaryFormStyles = createStylesUtils({
  container: {
    backgroundImage: `url(${images.summary.summaryBackground})`,
    width: '500px',
    height: '200px',
  },

  subtitle: {
    color: '#292D32',
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: '500',
    textAlign: 'center',
  },

  containerMobile: {
    width: '335px',
    height: '177px',
    backgroundColor: 'white',
    borderRadius: '24px',
  },

  image: { width: '130px', height: '130px' },

  membership: {
    color: '#0ABAB5',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '16px',
  },

  oneOffPayment: {
    marginTop: '8px',
    color: '#26262680',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '22px',
  },

  sum: {
    marginBottom: '24px',
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '32px',
    color: '#262626',
  },

  sumMobile: {
    fontSize: '18px',
    lineHeight: '26px',
  },

  numberOfSeats: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#292D32',
  },

  numberOfSeatsInput: {
    '&.MuiOutlinedInput-root': {
      width: '80px',
      backgroundColor: 'white',
    },

    '& .MuiOutlinedInput-input': {
      textAlign: 'center',
      color: '#0ABAB5',
      fontFamily: 'GeneralSans',
      fontWeight: 600,
      fontSize: '16px',

      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        opacity: 1,
      },
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '0px',
    },

    '&.Mui-error': {
      border: '3px solid red',

      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: '0px solid #292D32',
        },
      },

      '& .MuiOutlinedInput-input': {
        color: 'red',
      },
    },

    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: '3px solid #0ABAB5',
      },
    },
  },
});
