import builder, { BuilderComponent, useIsPreviewing } from '@builder.io/react';
import { useMemo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

builder.init('03ed57a0336a4adf890716fd06b37113');

export default function BlogPage() {
  const { slug } = useParams<{ slug: string }>();

  const isPreviewingInBuilder = useIsPreviewing();

  const [notFound, setNotFound] = useState(false);
  const [content, setContent] = useState(null);
  const [article, setArticle] = useState(null);

  const locale = useMemo(() => {
    const storedLocale = localStorage.getItem('locale');
    return storedLocale ? storedLocale : undefined;
  }, []);

  // get the page content from Builder
  useEffect(() => {
    async function fetchContent() {
      const content = await builder
        .get('page', {
          url: '/blog',
          userAttributes: {
            locale,
          },
          options: {
            locale,
          },
        })
        .promise();

      setContent(content);
      setNotFound(!content);
    }

    async function fetchBlogPost() {
      const article = await builder
        .get('blog-posts', {
          query: {
            data: {
              slug,
            },
          },
        })
        .promise();

      setArticle(article);
    }

    fetchContent();
    fetchBlogPost();
  }, [locale, slug]);

  if (notFound && !isPreviewingInBuilder) {
    return (
      <div style={{ backgroundColor: 'blue', width: '100%', height: '100%' }} />
    );
  }

  return (
    <BuilderComponent
      model="page"
      content={content!}
      locale={locale}
      data={{ article }}
    />
  );
}
