import { Box, Fade, Stack, Typography } from '@mui/material';
import { useCallback, useState } from 'react';

import { images } from '../assets/assets';
import { smeStyles } from '../styles/sme';
import { FADE_TIMEOUT } from '../styles/stylesUtils';
import { LoadingButton } from '@mui/lab';

import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useAppSelector } from '../redux/store';
import { CHECKOUT_DATA_KEY } from './AllDone';
import { useTranslation } from 'react-i18next';

const CreditCardForm = () => {
  const { t } = useTranslation();

  const stripe = useStripe();
  const elements = useElements();

  const checkoutData = useAppSelector((state) => state.checkout.checkoutData);
  const [loading, setLoading] = useState(false);

  const onContinuePressed = useCallback(() => {
    if (!stripe || !elements) {
      return;
    }

    setLoading(true);
    localStorage.setItem(CHECKOUT_DATA_KEY, JSON.stringify(checkoutData));

    stripe
      .confirmPayment({
        elements,
        confirmParams: { return_url: window.location.href.replace('5', '6') },
      })
      .finally(() => setLoading(false));
  }, [checkoutData, elements, stripe]);

  return (
    <Fade in={true} timeout={FADE_TIMEOUT} appear={true}>
      <Stack
        sx={[smeStyles.content, { maxWidth: '576px' }]}
        justifyContent={'space-between'}
        alignSelf={'center'}
        alignItems={'center'}
      >
        <Typography variant={'h1'}>{t('cardDetails')}</Typography>

        <Box sx={{ width: '100%' }}>
          <PaymentElement />
        </Box>

        <LoadingButton
          variant="contained"
          endIcon={<Box component={'img'} src={images.button.arrowRight} />}
          disabled={!stripe || !elements}
          sx={{
            alignSelf: 'center',
            margin: '20px 0px 40px',
          }}
          loading={loading}
          onClick={onContinuePressed}
        >
          {t('payAndActivateNow')}
        </LoadingButton>
      </Stack>
    </Fade>
  );
};

export default CreditCardForm;
