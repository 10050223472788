import { createStylesUtils } from './stylesUtils';

export const smeStyles = createStylesUtils({
  container: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    overflowY: 'scroll',
    background: '#F5F6F6',
  },

  content: {
    width: '100%',
    maxWidth: '488px',
    flex: 1,
    boxSizing: 'border-box',
    padding: '20px 0px 62px',
  },
});

export const formStyles = createStylesUtils({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },

  formContainer: {
    width: 'calc(100% - 20px)',
    maxWidth: '488px',
    alignItems: 'center',
  },

  slider: {
    flexGrow: 1,
    maxWidth: '540px',
    width: '0px',
    height: '20px',
    color: 'transparent',
    background: 'rgba(255, 255, 255, 0.3)',
    border: '10px solid #FFFFFF',
    borderRadius: '36px',

    '& .MuiSlider-thumb': {
      backgroundColor: 'white',
      width: '50px',
      height: '50px',
      border: '20px solid #262626',
    },
    '& .MuiSlider-mark': {
      backgroundColor: '#292D3266',
      height: '20px',
      marginTop: '-40px',
      zIndex: -100,
    },
  },

  sliderLabel: {
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#292D3266',
  },

  answerText: {
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '32px',
    color: '#262626',
  },
});

export const largeInputStyles = createStylesUtils({
  container: {
    width: '100%',
    maxWidth: '700px',
    padding: '0px 0px',
    alignSelf: 'center',
  },

  countryInput: {
    width: 'calc(100% - 40px)',
    maxWidth: '700px',
    alignSelf: 'center',

    '& .MuiInput-input': {
      borderWidth: '1px',
    },
    '&.MuiInput-root': {
      margin: `0px 0px`,
      ':before, :after': {
        borderBottom: '2px solid #292D32',
      },
    },
  },

  errorCountryInput: {
    color: 'red',

    '&.MuiInput-root': {
      margin: `0px 0px`,
      ':before, :after': {
        borderBottom: '2px solid red',
      },
    },
  },

  countryStartAdornment: (theme) => ({
    '&.MuiInputAdornment-positionStart': {
      marginRight: theme.spacing(1),
    },
  }),
});
