import { Box, Stack, Theme, Typography } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { useCallback } from 'react';
import { createStylesUtils } from '../styles/stylesUtils';
import { useTranslation } from 'react-i18next';

export default function ProgressBar({
  progress,
  step,
  text = 'Sign up',
  total,
  subtitleText,
}: {
  progress: number;
  step: number;
  total?: number;
  text?: string;
  subtitleText?: string;
}) {
  const { t } = useTranslation();

  const width = useCallback(
    (index: number, numberOfItems: number) => {
      const itemProgress = 100 / numberOfItems;
      const itemMaxProgress = index * itemProgress;
      const itemValue = progress - itemMaxProgress;

      if (itemValue < 0) {
        return 0;
      } else if (itemValue >= itemProgress) {
        return 100;
      } else {
        return Math.round((itemValue / itemProgress) * 100);
      }
    },
    [progress],
  );

  return (
    <Stack alignItems="center" sx={{ marginTop: '20px', alignSelf: 'stretch' }}>
      <Typography variant="h3">{text}</Typography>

      <Stack
        direction="row"
        sx={{ margin: '20px 0px 8px 0px', alignSelf: 'stretch' }}
        justifyContent="center"
      >
        <ProgressItem width={width(0, 3)} />
        <ProgressItem
          width={width(1, 3)}
          sx={{ margin: '0px 10px 0px 10px' }}
        />
        <ProgressItem width={width(2, 3)} />
      </Stack>

      {subtitleText !== undefined ? (
        <Typography variant="subtitle1">{subtitleText}</Typography>
      ) : (
        <Typography variant="subtitle1">
          {t('step')} {step}
          {total !== undefined ? ` / ${total}` : ''}
        </Typography>
      )}
    </Stack>
  );
}

const ProgressItem = ({
  sx,
  width,
}: {
  sx?: SystemStyleObject<Theme>;
  width: number;
}) => {
  return (
    <Box
      sx={[
        {
          width: 'calc(33% - 20px)',
          maxWidth: '150px',
          height: 6,
          position: 'relative',
        },
        sx ?? {},
      ]}
    >
      <Box sx={progressStyles.background} />
      <Box
        sx={[
          progressStyles.foreground,
          { width: `${width}%` },
          width === 100 ? { background: '#0ABAB5' } : {},
        ]}
      />
    </Box>
  );
};

const progressStyles = createStylesUtils({
  background: {
    width: '100%',
    height: '100%',
    background: 'white',
    borderRadius: 6,
  },

  foreground: {
    position: 'absolute',
    height: '100%',
    background: 'black',
    borderRadius: 6,

    top: 0,
    left: 0,
  },
});
