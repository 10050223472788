import { Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { smeStyles } from '../styles/sme';
import Header from '../components/Header';
import SignUpForm from '../components/SignUpForm';
import { useMemo } from 'react';
import CompanySizeForm from '../components/CompanySizeForm';
import PickYourPlan from '../components/PickYourPlan';
import SummaryForm from '../components/SummaryForm';
import AllDone from '../components/AllDone';

import StripeWrapper from '../components/StripeForm';
import ContactSales from '../components/ContactSales';

export default function SMERouter() {
  const { step } = useParams<{ step: string }>();

  const content = useMemo(() => {
    switch (step) {
      case '1':
        return <SignUpForm />;
      case '2':
        return <CompanySizeForm />;
      case '3':
        return <PickYourPlan />;
      case '4':
        return <SummaryForm />;
      case '5':
        return <StripeWrapper />;
      case '6':
        return <AllDone />;
      case '7':
        return <ContactSales />;
      case '8':
        return <AllDone pokoFlow={true} />;
    }
  }, [step]);

  return (
    <Stack sx={smeStyles.container}>
      <Header
        poko={['7', '8'].includes(step || '')}
        step={parseInt(step ?? '1')}
      />

      {content}
    </Stack>
  );
}
