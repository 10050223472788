import { Box, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { createStylesUtils } from '../styles/stylesUtils';
import { images } from '../assets/assets';

export default function HeaderBack() {
  const navigate = useNavigate();

  return (
    <Stack
      gap={'8px'}
      direction={'row'}
      alignItems={'center'}
      sx={styles.container}
      onClick={() => navigate(-1)}
    >
      <Box component={'img'} src={images.navigation.back} />
      <Typography sx={styles.backText}>Back</Typography>
    </Stack>
  );
}

const styles = createStylesUtils({
  container: {
    position: 'absolute',
    top: '20px',
    transform: 'translateX(calc(-50% - 400px))',
    left: '50%',

    '&:hover': {
      cursor: 'pointer',
    },
  },

  backText: {
    fontWeight: 500,
    fontSize: '15px',
    color: 'black',
  },
});
