import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface CheckoutData {
  name: string;
  companyName: string;
  email: string;
  password: string;
  companySize: number;
  planId: number;
  numberOfSeats: number;
  coupon?: string;
}

interface State {
  checkoutData: CheckoutData;
}

const initialState: State = {
  checkoutData: {
    name: '',
    email: '',
    password: '',
    companyName: '',
    companySize: 0,
    planId: 1,
    numberOfSeats: 0,
  },
};

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setCheckoutData(state, action: PayloadAction<Partial<CheckoutData>>) {
      state.checkoutData = { ...state.checkoutData, ...action.payload };
    },
  },
});

export default checkoutSlice.reducer;

export const checkoutActions = checkoutSlice.actions;
