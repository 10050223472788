import { Box, Fade, Stack, Typography } from '@mui/material';
import { smeStyles } from '../styles/sme';
import { FADE_TIMEOUT } from '../styles/stylesUtils';
import { images } from '../assets/assets';
import { useCallback, useEffect, useRef, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useSearchParams } from 'react-router-dom';
import { useMakePurchaseMutation } from '../redux/api';
import { CheckoutData } from '../redux/checkout';
import { appEnvironment } from '../common/environment';
import { useTranslation } from 'react-i18next';

export const CHECKOUT_DATA_KEY = 'checkoutDataKey';

export default function AllDone(props: Props) {
  const { t } = useTranslation();
  const { pokoFlow } = props;

  const onContinuePressed = useCallback(() => {
    window.open(appEnvironment.dashboardURL, '_blank');
  }, []);

  const [searchParams] = useSearchParams();

  const [purchaseSuccessful, setPurchaseSuccessful] = useState(false);
  const [loading, setLoading] = useState(true);

  const hasAttemptedPurchase = useRef(false);

  const [makePurchase] = useMakePurchaseMutation();

  useEffect(() => {
    const paymentIntent = searchParams.get('payment_intent');
    const noIntentRequired = searchParams.get('no_intent_required');

    if ((paymentIntent || noIntentRequired) && !hasAttemptedPurchase.current) {
      hasAttemptedPurchase.current = true;
      const data = JSON.parse(
        localStorage.getItem(CHECKOUT_DATA_KEY) ?? '',
      ) as CheckoutData;

      makePurchase({
        ...data,
        stripePaymentIntentId: paymentIntent ?? undefined,
      })
        .unwrap()
        .then(() => setPurchaseSuccessful(true))
        .finally(() => setLoading(false));
    }
  }, [makePurchase, searchParams]);

  return (
    <Fade in={true} timeout={FADE_TIMEOUT} appear={true}>
      <Stack
        sx={smeStyles.content}
        justifyContent={'space-between'}
        alignSelf={'center'}
        alignItems={'center'}
      >
        <Stack alignItems={'center'} sx={{ gap: '20px' }}>
          <Typography variant={'h1'}>
            {pokoFlow
              ? t('thankYou')
              : purchaseSuccessful
              ? t('allDone')
              : t('pleaseWait')}
          </Typography>
          <Typography variant={'headerSubtitle'} sx={{ color: '#292D3299' }}>
            {pokoFlow
              ? t('ourSalesManager')
              : purchaseSuccessful
              ? t('signInToTheDashboard')
              : t('doNotClose')}
          </Typography>
        </Stack>

        <Box component={'img'} src={images.allDone} />

        {!pokoFlow && (
          <LoadingButton
            variant="contained"
            loading={loading}
            disabled={!purchaseSuccessful}
            endIcon={<Box component={'img'} src={images.button.arrowRight} />}
            sx={{
              alignSelf: 'center',
              margin: '20px 0px 40px',
            }}
            onClick={onContinuePressed}
          >
            {t('goToDashboard')}
          </LoadingButton>
        )}
      </Stack>
    </Fade>
  );
}

interface Props {
  pokoFlow?: boolean;
}
