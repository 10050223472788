import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { nodeClient } from '../common/environment';
import { Country, SMEDiscount, SMEPlan } from './types';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fakeBaseQuery(),

  endpoints: (builder) => ({
    emailAvailable: builder.mutation<{ available: boolean }, { email: string }>(
      {
        queryFn: async ({ email }) => {
          const { data } = await nodeClient.post<{ available: boolean }>(
            'sme/emailAvailable',
            { email },
          );

          return { data };
        },
      },
    ),

    verifyCoupon: builder.mutation<{ valid: boolean }, { coupon: string }>({
      queryFn: async ({ coupon }) => {
        const { data } = await nodeClient.post<{ valid: boolean }>(
          'sme/verifyCoupon',
          { coupon },
        );

        return { data };
      },
    }),

    getCountries: builder.query<Country[], boolean>({
      queryFn: async (filter) => {
        const { data } = await nodeClient.get<Country[]>(
          `countries?filter=${filter}`,
        );

        return { data };
      },
    }),

    getPrice: builder.mutation<
      { price: number; vat: number },
      { planId: number; numberOfSeats: number; coupon?: string }
    >({
      queryFn: async (args) => {
        const { data } = await nodeClient.post<{ price: number; vat: number }>(
          'sme/getPrice',
          args,
        );

        return { data };
      },
    }),

    makePurchase: builder.mutation<
      unknown,
      {
        stripePaymentIntentId?: string;
        email: string;
        password: string;
        name: string;
        companyName: string;
        planId: number;
        numberOfSeats: number;
        coupon?: string;
      }
    >({
      queryFn: async (args) => {
        await nodeClient.post('sme/makePurchase', args);
        return { data: {} };
      },
    }),

    getPlans: builder.query<
      { plans: SMEPlan[]; discount?: SMEDiscount },
      string | undefined
    >({
      queryFn: async (coupon: string) => {
        const { data } = await nodeClient.get<{
          plans: SMEPlan[];
          discount?: SMEDiscount;
        }>(`sme/plans${coupon !== undefined ? `?coupon=${coupon}` : ''}`);

        return { data };
      },
    }),

    sendPoko: builder.mutation<
      unknown,
      {
        fullName: string;
        email: string;
        message?: string;
        numberOfEmployees: number;
      }
    >({
      queryFn: async (args) => {
        await nodeClient.post('sme/createPokoMessage', args);
        return { data: {} };
      },
    }),
  }),
});

export const {
  useEmailAvailableMutation,
  useVerifyCouponMutation,

  useGetCountriesQuery,
  useGetPriceMutation,

  useMakePurchaseMutation,
  useGetPlansQuery,
  useSendPokoMutation,
} = api;
