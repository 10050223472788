import { useEffect, useRef, useState } from 'react';
import CreditCardForm from './CreditCardForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { appEnvironment, nodeClient } from '../common/environment';
import { useAppSelector } from '../redux/store';
import { shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CHECKOUT_DATA_KEY } from './AllDone';

const stripePromise = loadStripe(appEnvironment.stripeKey);

export default function StripeWrapper() {
  const [clientSecret, setClientSecret] = useState('');
  const navigate = useNavigate();

  const data = useAppSelector((state) => state.checkout.checkoutData, {
    equalityFn: shallowEqual,
  });

  const clientSecretRef = useRef(clientSecret);

  useEffect(() => {
    if (clientSecretRef.current === '') {
      nodeClient
        .post<{ clientSecret: string }>('sme/createPaymentIntent', data)
        .then(({ data: { clientSecret } }) => {
          if (clientSecret === 'no_intent_required') {
            localStorage.setItem(CHECKOUT_DATA_KEY, JSON.stringify(data));

            navigate('/sme/6?no_intent_required=true');
            return;
          }

          setClientSecret(clientSecret);
          clientSecretRef.current = clientSecret;
        });
    }
  }, [clientSecret, data, navigate]);

  if (!clientSecret) {
    return null;
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        appearance: {
          theme: 'stripe',

          variables: {
            fontFamily: 'GeneralSans',
            fontWeightNormal: '500',
            fontWeightMedium: '500',
            borderRadius: '20px',
            colorText: '#292D32',
          },

          rules: {
            '.Input': {
              boxShadow: 'none',
              border: '1.0px solid rgba(41, 45, 50, 0.6)',
              padding: '16px',
              backgroundColor: '#FFFFFF00',
            },
            '.Input:focus': {
              boxShadow: '0 0 0 3px #292D32',
            },
            '.Input::placeholder': {
              color: 'rgba(41, 45, 50, 0.6)',
              fontWeight: '500',
            },
            '.Input:focus::placeholder': {
              color: '#292D32',
              fontWeight: '500',
            },
            '.Label': {
              marginTop: '16px',
            },
          },
        },
        clientSecret,
      }}
    >
      <CreditCardForm />;
    </Elements>
  );
}
