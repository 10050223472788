import { useMediaQuery, useTheme } from '@mui/material';

export const useMobile = () => {
  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  return mobile;
};

export const useSmallDevice = () => {
  const smallDevice = useMediaQuery('(max-height:700px)');

  return smallDevice;
};
