import {
  Box,
  Checkbox,
  Fade,
  FormControlLabel,
  Grid,
  Typography,
  Stack,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMobile } from '../common/hooks';
import { images } from '../assets/assets';
import { appEnvironment, appAutoCompleteData } from '../common/environment';
import { FADE_TIMEOUT } from '../styles/stylesUtils';
import {
  useEmailAvailableMutation,
  useVerifyCouponMutation,
} from '../redux/api';
import { useAppDispatch } from '../redux/store';
import { checkoutActions } from '../redux/checkout';
import validator from '../common/validator';
import { formStyles, smeStyles } from '../styles/sme';
import TextInput from '../common/TextInput';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

const SignUpForm = () => {
  const { t } = useTranslation();
  const mobile = useMobile();

  const [searchParams] = useSearchParams();

  const [name, setName] = useState(
    appEnvironment.autocomplete
      ? appAutoCompleteData.name
      : searchParams.get('name') || '',
  );
  const [companyName, setCompanyName] = useState(
    appEnvironment.autocomplete
      ? appAutoCompleteData.companyName
      : searchParams.get('companyName') || '',
  );
  const [coupon, setCoupon] = useState(
    appEnvironment.autocomplete
      ? appAutoCompleteData.coupon
      : searchParams.get('coupon') || '',
  );
  const [email, setEmail] = useState(
    appEnvironment.autocomplete
      ? appAutoCompleteData.email
      : searchParams.get('email') || '',
  );
  const [password, setPassword] = useState(
    appEnvironment.autocomplete ? appAutoCompleteData.password : '',
  );

  const [tcsAgreed, setTcsAgreed] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const [nameError, setNameError] = useState('');
  const [companyNameError, setCompanyNameError] = useState('');
  const [couponError, setCouponError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const dispatch = useAppDispatch();

  const [checkEmailAvailability] = useEmailAvailableMutation();
  const [verifyCoupon] = useVerifyCouponMutation();

  const navigate = useNavigate();

  const hasErrors = useMemo(() => {
    return (
      name.length === 0 ||
      companyName.length === 0 ||
      email.length === 0 ||
      !validator.validEmail(email) ||
      password.length === 0 ||
      !validator.passwordLength(password) ||
      !validator.passwordHasSpecialCharacters(password) ||
      !validator.passwordHasUppercaseAndLowercase(password) ||
      !validator.passwordHasDigit(password) ||
      emailError.length > 0
    );
  }, [companyName.length, email, emailError.length, name.length, password]);

  const onInputChange = useCallback(
    (onChange: (text: string) => void, setError: (text: string) => void) => {
      return (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      ) => {
        setError('');
        onChange(event.target.value);
      };
    },
    [],
  );

  const onNameBlur = useCallback(() => {
    if (name.length === 0) {
      setNameError(t('thisFieldIsRequired'));
    }
  }, [name.length, t]);

  const onCompanyNameBlur = useCallback(() => {
    if (companyName.length === 0) {
      setCompanyNameError(t('thisFieldIsRequired'));
    }
  }, [companyName.length, t]);

  const onEmailBlur = useCallback(() => {
    if (email.length === 0) {
      setEmailError(t('thisFieldIsRequired'));
    } else if (!validator.validEmail(email)) {
      setEmailError(t('invalidEmail'));
    }
  }, [email, t]);

  const onPasswordBlur = useCallback(() => {
    if (password.length === 0) {
      setPasswordError(t('thisFieldIsRequired'));
    } else if (!validator.passwordLength(password)) {
      setPasswordError(t('passwordsMustBe8Characters'));
    } else if (!validator.passwordHasUppercaseAndLowercase(password)) {
      setPasswordError(t('passwordMustHaveBothUppercase'));
    } else if (!validator.passwordHasDigit(password)) {
      setPasswordError(t('passwordsMustContainAtLeastOneDigit'));
    } else if (!validator.passwordHasSpecialCharacters(password)) {
      setPasswordError(t('passwordsMustContainOneSpecial'));
    }
  }, [password, t]);

  const onContinuePressed = useCallback(async () => {
    onNameBlur();
    onEmailBlur();
    onPasswordBlur();

    if (!hasErrors) {
      const { available } = await checkEmailAvailability({ email }).unwrap();
      if (!available) {
        setEmailError(t('emailIsAlreadyTaken'));
        return;
      }
      if (coupon) {
        setLoading(true);
        const { valid } = await verifyCoupon({ coupon }).unwrap();
        if (!valid) {
          setCouponError(t('invalidCoupon'));
          setLoading(false);
          return;
        }
      }

      try {
        dispatch(
          checkoutActions.setCheckoutData({
            name,
            email,
            password,
            coupon: coupon ? coupon : undefined,
            companyName,
          }),
        );
        navigate('/sme/2');
      } catch (e) {
        setCouponError(t('invalidCoupon'));
      } finally {
        setLoading(false);
      }
    }
    return {};
  }, [
    checkEmailAvailability,
    companyName,
    coupon,
    dispatch,
    email,
    hasErrors,
    name,
    navigate,
    onEmailBlur,
    onNameBlur,
    onPasswordBlur,
    password,
    t,
    verifyCoupon,
  ]);

  useEffect(() => {
    setButtonDisabled(hasErrors || !tcsAgreed);
  }, [hasErrors, tcsAgreed]);

  return (
    <Fade in={true} timeout={FADE_TIMEOUT} appear={true}>
      <Stack
        sx={smeStyles.content}
        justifyContent={'space-between'}
        alignSelf={'center'}
        alignItems={'center'}
      >
        <Stack alignItems={'center'} sx={{ gap: '20px' }}>
          <Typography variant={'h1'}>{t('createAccount')}</Typography>
          <Typography variant={'headerSubtitle'}>
            {t('pleaseFillInDetails')}
          </Typography>
        </Stack>

        <Box sx={formStyles.container}>
          <Stack sx={formStyles.formContainer}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <TextInput
                  placeholder={t('fullName')}
                  value={name}
                  endIcon={images.form.nameInput}
                  onChange={onInputChange(setName, setNameError)}
                  onBlur={onNameBlur}
                  helperText={nameError}
                  error={nameError.length > 0}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextInput
                  placeholder={t('accessCode')}
                  value={coupon}
                  endIcon={images.form.accessCode}
                  onChange={onInputChange(setCoupon, setCouponError)}
                  helperText={couponError}
                  error={couponError.length > 0}
                />
              </Grid>

              <Grid item xs={12}>
                <TextInput
                  placeholder={t('companyName')}
                  value={companyName}
                  onChange={onInputChange(setCompanyName, setCompanyNameError)}
                  onBlur={onCompanyNameBlur}
                  helperText={companyNameError}
                  error={companyNameError.length > 0}
                />
              </Grid>

              <Grid item xs={12}>
                <TextInput
                  placeholder={t('email')}
                  value={email}
                  endIcon={images.form.email}
                  onChange={onInputChange(setEmail, setEmailError)}
                  onBlur={onEmailBlur}
                  helperText={emailError}
                  error={emailError.length > 0}
                  type="email"
                />
              </Grid>

              <Grid item xs={12}>
                <TextInput
                  placeholder={t('password')}
                  value={password}
                  focused={true}
                  endIcon={images.form.password}
                  onChange={onInputChange(setPassword, setPasswordError)}
                  onBlur={onPasswordBlur}
                  helperText={passwordError}
                  error={passwordError.length > 0}
                  type="password"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography sx={{ marginLeft: '8px' }} variant="body2">
                  {t('atLeast8Characters')}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    marginLeft: '8px',
                    textAlign: mobile ? 'left' : 'end',
                  }}
                  variant="body2"
                >
                  {t('bothUppercaseAndLowercase')}
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                container
                justifyContent="space-between"
              >
                <Typography sx={{ marginLeft: '8px' }} variant="body2">
                  {t('mixtureOfLetters')}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    marginLeft: '8px',
                    textAlign: mobile ? 'left' : 'end',
                  }}
                  variant="body2"
                >
                  {t('atLeastOneSpecialCharacter')}
                </Typography>
              </Grid>
            </Grid>

            <FormControlLabel
              sx={(theme) => ({ marginTop: theme.spacing(2) })}
              label={
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography variant="body1">
                    {t('iAgreeWith')}&nbsp;
                  </Typography>

                  <a href="https://syd.life/terms/user_terms.html">
                    <Typography variant="body1">
                      {t('termsAndConditions')}
                    </Typography>
                  </a>
                </Box>
              }
              control={
                <Checkbox
                  onChange={(_event, checked) => {
                    setTcsAgreed(checked);
                  }}
                />
              }
            />
          </Stack>
        </Box>

        <LoadingButton
          variant="contained"
          endIcon={<Box component={'img'} src={images.button.arrowRight} />}
          disabled={buttonDisabled}
          loading={loading}
          sx={{
            alignSelf: 'center',
            margin: '20px 0px 40px',
          }}
          onClick={onContinuePressed}
        >
          {t('confirm')}
        </LoadingButton>
      </Stack>
    </Fade>
  );
};

export default SignUpForm;
